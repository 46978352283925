import { API_URL } from 'constanst';
import { useQuery } from 'react-query';

export interface QueryParams {
    [key: string]: any
}

export const fetchData = async function (path: string, queryParams?: QueryParams) {
    const url = new URL(`${API_URL}/${path}`);

    if (queryParams) {
        Object.keys(queryParams).forEach(key => {
            url.searchParams.append(key, queryParams[key])
        });
    }

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        method: 'GET',

    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
}

function useFetchQuery(path: string, queryKey: string, queryParams?: QueryParams) {
    return useQuery(queryKey, () => fetchData(path, queryParams));
}

export default useFetchQuery;
