import { styled, Typography } from "@mui/material";

const Title = styled(Typography)<{ color?: string }>`
  background-color: transparent;
  color: ${({ theme, color }) =>
    color ? color === "black" ?? theme.palette[color].main : "white"};
  display: flex;
  font-family: Poppins;
  font-weight: bold;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
  text-align: center;
`;

export default Title;
