export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const expiresAt = () => {
  const currentTime = new Date().getTime(); // Get the current time in milliseconds
  const newTime = currentTime + 28800 * 1000; // Add 1800 seconds and convert to milliseconds
  const newDate = new Date();
  newDate.setTime(newTime);

  return newDate;
};

export const parseDate = (date: string): string => {
  const parse = new Date(date);
  const month = parse.toLocaleString("es-ES", { month: "short" });
  const year = parse.toLocaleString("es-ES", { year: "2-digit" });

  return `${parse.getDate()} de ${month}, ${year} ${parse.toLocaleTimeString()}`;
};
