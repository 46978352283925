import React from "react";
import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import { Close as CloseIcon } from "@mui/icons-material";

interface ISnackBar {
  openSnackBar: (message: string, severity?: AlertColor) => void;
}

const SnackBarContext = React.createContext<ISnackBar>(null);

export const SnackBarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [severity, setSeverity] = React.useState<AlertColor>("success");

  const openSnackBar = (message: string, severity: AlertColor) => {
    setSeverity(severity);
    setMessage(message);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setMessage("");
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  type TransitionProps = Omit<SlideProps, "direction">;

  function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <SnackBarContext.Provider value={{ openSnackBar }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
        action={action}
        key={message}
        TransitionComponent={TransitionRight}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export const useSnackbar = () => {
  return React.useContext(SnackBarContext);
};
