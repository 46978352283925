import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CardContent, Paper, Typography } from "@mui/material";
import { GridContainer, GridItem } from "Components/Grid";
import { Spacing } from "Components/Shared/Spacing";
import {
  Person as PersonIcon,
  CompareArrows as CompareArrowsIcon,
  SettingsInputAntenna as SettingsInputAntennaIcon,
  PushPin as PushPinIcon,
  CalendarMonth as CalendarMonthIcon,
  WatchLater as WatchLaterIcon,
  ArrowBackIosNew,
} from "@mui/icons-material";
import { STATUSES } from "./constants";
import { Card } from "./card";
import { IncidenceDetailItem } from "./incidenceDetailItem";
import { IncidenceActions } from "./incidenceActions";
import { IncidenceTimeLine } from "./incidenceTimeLine";
import useFetchQuery from "@hooks/fetchData";

export const IncidenceDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useFetchQuery(`incidence/${id}/details`, 'incidenceStatus');

  const incidence = React.useMemo(
    () => data?.incidence ?? {},
    [data]
  );

  const assignee = incidence.assignBy
    ? `${incidence?.assignBy?.name} ${incidence?.assignBy?.lastname}`
    : "Sin asignar";

  const statuses = React.useMemo(
    () => data?.statuses ?? [],
    [data]
  );

  const status = React.useMemo(
    () => STATUSES[statuses[statuses.length - 1]?.status ?? "UNASSIGN"],
    [statuses]
  );

  return (
    <Card elevation={0}>
      <CardContent>
        <GridContainer columns={1}>
          <GridItem>
            <Typography
              fontFamily="Poppins"
              fontWeight={700}
              fontSize="1.5rem"
              lineHeight="2.25rem"
              display="flex"
              alignItems="center"
            >
              <ArrowBackIosNew
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              INCIDENCIA {status}
            </Typography>
          </GridItem>
          <GridItem>
            <GridContainer columnSpacing={"1rem"}>
              <GridItem columnSize={4} md={4}>
                <GridContainer>
                  <IncidenceDetailItem
                    title={assignee}
                    icon={<PersonIcon />}
                    loading={isLoading}
                  />
                  <IncidenceDetailItem
                    title={incidence?.poste?.direction}
                    icon={<CompareArrowsIcon />}
                    loading={isLoading}
                  />
                  <IncidenceDetailItem
                    title={incidence?.poste?.name}
                    icon={<SettingsInputAntennaIcon />}
                    loading={isLoading}
                  />
                  <IncidenceDetailItem
                    title={incidence?.poste?.kilometer}
                    icon={<PushPinIcon />}
                    loading={isLoading}
                  />
                  <IncidenceDetailItem
                    title={new Date(incidence?.dateTime).toLocaleDateString()}
                    icon={<CalendarMonthIcon />}
                    loading={isLoading}
                  />
                  <IncidenceDetailItem
                    title={new Date(incidence?.dateTime).toLocaleTimeString()}
                    icon={<WatchLaterIcon />}
                    loading={isLoading}
                  />
                </GridContainer>
              </GridItem>
              <GridItem columnSize={3} md={3}>
                <GridContainer columns={1}>
                  <GridItem>
                    <Typography>Descripción</Typography>
                  </GridItem>
                  <GridItem>
                    <Paper sx={{ borderRadius: "1rem" }}>
                      <Spacing>{incidence.description}</Spacing>
                    </Paper>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem columnSize={5} md={3}>
                <IncidenceActions
                  refetch={refetch}
                  incidenceId={id}
                  status={status}
                  type={incidence.type}
                  recording={incidence.callRecording}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem>
            <div style={{ height: "315px", overflow: "scroll" }}>
              <IncidenceTimeLine statuses={statuses} />
            </div>
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
};
