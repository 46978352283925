import React from "react";
import useFetchQuery from "@hooks/fetchData";

import { BarChart } from "Components/Shared/Graphs/BarChart";
import { PieChart } from "Components/Shared/Graphs/PieChart";
import { GridContainer } from "Components/Grid";
import { StackedChart } from "Components/Shared/Graphs/StackedChart";
import { METRICS_DEFAULT, Metrics, getMetrics } from "./constants";
import { DashboardItem } from "./Item";

export const Dashboard = () => {
  const { data } = useFetchQuery("incidence", "metrics");

  const metrics = React.useMemo<Metrics>(
    () =>
      data
        ? data.incidences.reduce(getMetrics, METRICS_DEFAULT)
        : METRICS_DEFAULT,
    [data]
  );

  return (
    <GridContainer>
      <DashboardItem title="Postes">
        <BarChart data={metrics.postes} dataKey="incidencias" />
      </DashboardItem>
      <DashboardItem title="Incidencias">
        <PieChart data={metrics.incidencias} />
      </DashboardItem>
      <DashboardItem title="Asignaciones" fullWidth>
        <StackedChart data={metrics.usuarios} />
      </DashboardItem>
    </GridContainer>
  );
};
