import { Chip } from "@mui/material";
import { AssigneeModal } from "Components/Assignee/custom-components";
import { GridContainer, GridItem } from "Components/Grid";
import ReactAudioPlayer from "react-audio-player";
// import { STATUSES, __CreateIncidenceDetail } from "./constants";
import { STATUSES } from "./constants";
import useMutationHook from "@hooks/useMutation";
import { API_URL } from "constanst";

interface Props {
  status: string;
  type: string;
  recording: string;
  incidenceId: string;
  refetch: () => void;
}
export const IncidenceActions: React.FC<Props> = ({
  status,
  type,
  recording,
  incidenceId,
  refetch,
}) => {
  const { mutate: createIncidenceDetail } = useMutationHook(
    `incidence/${incidenceId}/details`,
    "createIncidenceDetail"
  );

  const handleAssigneeSave = (status) => {
    createIncidenceDetail({ status });
    setTimeout(refetch, 200);
  };

  return (
    <GridContainer columnSpacing="2.25rem">
      <GridItem>
        <Chip color="success" label={status} style={{ width: "100%" }} />
      </GridItem>
      <GridItem>
        {status !== STATUSES.CLOSE && (
          <AssigneeModal
            type="INPROCESS"
            triggerText={
              status === STATUSES.UNASSIGN ? "Asignar" : "Re-Asignar"
            }
            title={`${status === STATUSES.UNASSIGN ? "" : "Re-"
              }Asignar usuario`}
            incidence={incidenceId}
            onSave={handleAssigneeSave}
          />
        )}
      </GridItem>
      <GridItem>
        <Chip
          color={type === "SENSOR" ? "warning" : "error"}
          label={type}
          style={{ width: "100%", color: "white" }}
        />
      </GridItem>
      <GridItem>
        {status === STATUSES.UNASSIGN ||
          status === STATUSES.CLOSE ? undefined : (
          <AssigneeModal
            type="CLOSE"
            triggerText="Cerrar"
            title="Cerrar Incidencia"
            incidence={incidenceId}
            onSave={handleAssigneeSave}
          />
        )}
      </GridItem>
      <GridItem>
        {recording &&
          // src={`https://storage.googleapis.com/sip-recordings/${recording}.wav`}
          (
            <ReactAudioPlayer
              onError={(e) => console.error('Audio Error:', e)}
              src={`${API_URL}/statics/recordings/${recording}.wav`}
              controls
            />
          )}
      </GridItem>
    </GridContainer>
  );
};

/**
 * 
 */