import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNotification } from "@hooks/notifications";
import { parseDate } from "utils";
import { colors, notificationsType } from "./contants";
import { SosRounded } from "@mui/icons-material";
import { AssigneeModal } from "Components/Assignee";

export const NotificationItems: React.FC = () => {
  const { notifications } = useNotification();

  return (
    <List
      sx={{
        minWidth: { sm: "400px", xs: "100%" },
      }}
    >
      {notifications?.map((notification, key) => {
        const index =
          notificationsType.findIndex(
            (sensorType) => sensorType === notification.description
          ) ?? 0;

        return (
          <ListItemButton
            alignItems="flex-start"
            key={key}
            selected={!notification.read}
          >
            <ListItemIcon>
              <SosRounded color={colors[index]} />
            </ListItemIcon>
            <ListItemText
              primary={`${notification.poste.name} - ${notification.description}`}
              secondary={parseDate(notification.dateTime)}
            />
            <ListItemIcon>
              <AssigneeModal
                incidence={notification._id}
                disabled={!!notification.assignBy}
              />
            </ListItemIcon>
          </ListItemButton>
        );
      })}
    </List>
  );
};
