import React from "react";

import { Button, CircularProgress, TextField } from "@mui/material";
import { GridContainer, GridItem } from "Components/Grid";
import { Modal } from "Components/Modal";
// import { _GetAllUsersQuery } from "Components/Assignee/queries";
import { DropDown } from "Components/Dropdown";
// import { __CreateIncidenceDetail } from "../constants";
import useFetchQuery from "@hooks/fetchData";
import useMutationHook from "@hooks/useMutation";
import { CallDetail } from "./callDetail";

export const CallIncidenceForm = ({
  openForm,
  incidenceId,
  hangup,
  posteInfo,
}) => {
  const [isOpen, setIsOpen] = React.useState(openForm);
  const [form, setForm] = React.useState({ description: "", assignee: "" });
  const { data, isLoading } = useFetchQuery('users', 'getUsers')
  const { mutate: createIncidenceDetail } = useMutationHook(`incidence/${incidenceId}/details`, 'createIncidenceDetail');

  const users = React.useMemo(
    () =>
      data?.users.map(user => ({ label: `${user.name} ${user.lastname}`, value: user._id })) ?? [],
    [data]
  );

  const updateIncidence = () => {
    if (form.assignee && form.description && incidenceId) {
      createIncidenceDetail({
        status: {
          assignee: form.assignee,
          comment: form.description,
          incidenceId,
          status: "INPROCESS",
        }
      });
      hangup();
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    setIsOpen(openForm);
  }, [openForm]);

  const handleOnChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = ({ target: { value } }) => {
    setForm((prev) => ({ ...prev, assignee: value }));
  };

  return (
    <>
      {isOpen && <CallDetail posteInfo={posteInfo} />}
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="ASIGNAR INCIDENCIA"
        noCloseBtn
      >
        <GridContainer columns={1}>
          <GridItem>
            <DropDown
              fullWidth
              value={form.assignee}
              onChange={handleSelectChange}
              collection={users}
              name="assignee"
              label="Asignar a"
              helperText="Usuario al que se le asignará la llamada"
            />
          </GridItem>
          <GridItem>
            <TextField
              name="description"
              multiline
              inputProps={{ minRows: 3, maxRows: 10 }}
              value={form.description}
              onChange={handleOnChange}
              placeholder="Descripción"
              fullWidth
            />
          </GridItem>
          <GridItem>
            <Button
              fullWidth
              color="success"
              onClick={updateIncidence}
              variant="contained"
            >
              {!isLoading && "Crear y Colgar"}
              {isLoading && <CircularProgress />}
            </Button>
          </GridItem>
        </GridContainer>
      </Modal>
    </>
  );
};


