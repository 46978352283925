import { Dashboard } from "Components/Dashboard";
import { RequiredAuth } from "RequiredAuth";

export const DashboardView = () => {
  return (
    <RequiredAuth>
      <Dashboard />
    </RequiredAuth>
  );
};
