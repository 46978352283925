
import { useMutation } from 'react-query';
import { API_URL } from 'constanst';
import { useSnackbar } from '@hooks/SnackBar';
import { useLoader } from '@hooks/loader';
import { QueryParams } from '@hooks/fetchData';

async function postData(path: string, data: QueryParams) {
    const response = await fetch(`${API_URL}/${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(data),
    });

    const body = await response.json();
    let error = false;
    if (!response.ok) {
        error = true;
    }

    return { ...body, error };
}

function useMutationHook<TData, QueryParams>(mutationPath: string, mutationName: string, pathParam?: string) {
    const { openSnackBar } = useSnackbar();
    const { openLoader, closeLoader } = useLoader()

    const mutateFunction = (data: any) => {
        const { pathParams, ...body } = data
        let path = mutationPath;
        if (pathParams) {
            path = mutationPath.replace(`{${pathParam}}`, pathParams[pathParam]);
        }

        return postData(path, body);
    }

    return useMutation<TData, unknown, QueryParams>({
        mutationFn: mutateFunction,
        mutationKey: mutationName,
        onMutate: () => {
            openLoader();
        },
        onSuccess: (data: any) => {
            if (data.error) {
                openSnackBar(data.message, 'error');
            }
        },
        onSettled: () => {
            closeLoader();
        }
    });
}

export default useMutationHook;