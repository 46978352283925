import React from "react";
import { IconButton } from "@mui/material";
import { AssignmentInd, PersonAdd } from "@mui/icons-material";

import { Modal, TriggerProps } from "./../Modal";
import { AssignneForm } from "./../Assignee/assigneeForm";
import { useNotification } from "@hooks/notifications";

interface Props {
  incidence: string;
  disabled?: boolean;
}

export const AssigneeModal: React.FC<Props> = ({ incidence, disabled }) => {
  const { handleOnSave } = useNotification();

  return (
    <Modal title="Asigna un usuario a la incidencia">
      <Trigger disabled={disabled} />
      <AssignneForm incidence={incidence} onSave={handleOnSave} />
    </Modal>
  );
};

interface TrigProps extends TriggerProps {
  disabled?: boolean;
}

const Trigger: React.FC<TrigProps> = ({ disabled, openModal }) => {
  return (
    <IconButton edge="end" onClick={openModal} disabled={disabled}>
      {disabled ? <AssignmentInd /> : <PersonAdd color="primary" />}
    </IconButton>
  );
};
