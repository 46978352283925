import { Skeleton, Typography } from "@mui/material";
import { GridItem, GridContainer } from "Components/Grid";
import React from "react";

interface Props {
  icon: React.ReactElement;
  title: string;
  loading?: boolean;
}

export const IncidenceDetailItem: React.FC<Props> = ({
  icon,
  title,
  loading,
  ...props
}) => (
  <GridItem {...props} md={6} xs={6}>
    <GridContainer>
      <GridItem columnSize={3} md={3} xs={3}>
        {icon}
      </GridItem>
      <GridItem columnSize={9} md={9} xs={9}>
        {loading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        ) : (
          <Typography>{title}</Typography>
        )}
      </GridItem>
    </GridContainer>
  </GridItem>
);
