import AdminRoute from "ACL/admin";
import { IncidenceDetail } from "Components/Incidencia/IncidenceDetail";
import { RequiredAuth } from "RequiredAuth";

export const IncidenceByIdView = () => {
  return (
    <RequiredAuth>
      <AdminRoute>
        <IncidenceDetail />
      </AdminRoute>
    </RequiredAuth>
  );
};
