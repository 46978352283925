import React from "react";
import { Add as AddIcon } from "@mui/icons-material";
import AddButton from "Components/Shared/AddButton";
import { Modal, TriggerProps } from "Components/Modal";
import { StepForm } from "../../StepForm";
import PosteForm from "../../Forms/PosteForm";
import ExtensionForm from "Components/Forms/ExtensionForm";

export const AddPoste: React.FC = () => {
  const inital = [
    {
      label: "Información general",
      component: <PosteForm />,
    },
    {
      label: "Extensión",
      component: <ExtensionForm type="poste" />,
    },
  ];
  const [steps, setSteps] = React.useState(inital);

  const updateSteps = (step: number, updates: { completed: boolean }) => {
    setSteps((prev) => {
      const newState = prev.map((item, index) =>
        index === step ? { ...item, ...updates } : item
      );

      return newState;
    });
  };

  const resetData = () => {
    setSteps(inital);
  };

  return (
    <Modal title="CREAR POSTE" onClose={resetData}>
      <Trigger />
      <StepForm steps={steps} updateSteps={updateSteps} />
    </Modal>
  );
};

const Trigger: React.FC<TriggerProps> = ({ openModal }) => {
  return (
    <AddButton
      onClick={openModal}
      AddIcon={AddIcon}
      title="CREAR NUEVO POSTE"
    />
  );
};
