import { Logout } from "Components/Logout";
import { RequiredAuth } from "RequiredAuth";

export const LogOutView = () => {
  return (
    <RequiredAuth>
      <Logout />
    </RequiredAuth>
  );
};
