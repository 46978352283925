import React from "react";
import { Grid, GridProps } from "@mui/material";

interface GridItemProps extends GridProps {
  parentColumnsize?: number;
  fullWidth?: boolean;
  children: React.ReactElement;
  columnSize?: number;
  gridRow?: any;
  style?: React.CSSProperties;
}

export const GridItem: React.FC<GridItemProps> = ({
  parentColumnsize,
  fullWidth,
  children,
  columnSize,
  gridRow,
  style,
  md,
  xs,
}) => {
  return (
    <Grid
      item
      gridRow={gridRow}
      lg={fullWidth ? 12 : columnSize ?? parentColumnsize}
      md={md}
      xs={xs || 12}
      style={style}
    >
      {children}
    </Grid>
  );
};
