import React from "react";
import { DialogContent, Slide, Typography, Paper } from "@mui/material";
import styled from "@emotion/styled";
import { TransitionProps } from "@mui/material/transitions";

import { GridItem } from "../../Grid";

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 2.25rem;
  z-index: 4017;
  display: flex;
  gap: 6.25rem;
`;

export const DataText = ({ children, ...props }) => (
  <GridItem {...props}>
    <Typography display="flex" alignItems="center" justifyContent="center">
      {children}
    </Typography>
  </GridItem>
);

export const PhoneContainer = styled(DialogContent)`
  background-color: white;
  height: 100%;
  margin: 0.625rem;
  border-radius: 1.5625rem;
  padding: 2rem;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  width: 37rem;
  z-index: 4017;
  top: -12px;
  left: -147px;
`;

export const PhonePaper = styled(Paper)`
  height: 35.5rem;
  width: 18.75rem;
  background-color: transparent;
  box-shadow: none;
`;

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
