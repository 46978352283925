import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { STATUSES } from "./constants";

export const IncidenceTimeLine = ({ statuses }) => {
  return (
    <List>
      {statuses.map((item, index) => (
        <React.Fragment key={`statuses-${index}`}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                {`${item.assignee.name.at(0)}${item.assignee.lastname.at(0)}`}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={STATUSES[item.status]}
              secondaryTypographyProps={{ noWrap: true, textOverflow: "wrap" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {`${item.assignee.name} ${item.assignee.lastname}`}
                  </Typography>
                  {` - ${item.comment}`}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};
