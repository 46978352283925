import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, InputAdornment, TextField } from "@mui/material";
import { Visibility as VisibilityIcon, Search as SearchIcon } from "@mui/icons-material";
import { DataGrid } from "../Shared/DataGrid";
import { DropDown } from "Components/Dropdown";
import { GridContainer, GridItem } from "Components/Grid";
import { SEARCH_BY } from "./constants";
import useFetchQuery from "@hooks/fetchData";


export const Incidencia: React.FC = () => {
  const [search, setSearch] = React.useState("");
  const [searchBy, setSearchBy] = React.useState(SEARCH_BY[0].value);
  const { data, isLoading } = useFetchQuery('incidence', 'incidencias');

  const navigate = useNavigate();

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearch(value);
  };

  const getAssignByName = (assignBy) => {
    if (!assignBy) {
      return "Sin asignar";
    }

    return `${assignBy?.name} ${assignBy?.lastname}`;
  };

  const rows = React.useMemo(
    () => data?.incidences.map((incidence) => ({
      ...incidence,
      updatedAt: new Date(incidence.updatedAt).toLocaleString(),
      createdAt: new Date(incidence.createdAt).toLocaleString(),
      dateTime: new Date(incidence.dateTime).toLocaleString(),
      poste: incidence.poste.name,
      assignBy: getAssignByName(incidence.assignBy),
      actions: (
        <Button color="inherit">
          <Link to={`/incidence/${incidence._id}`}>
            <VisibilityIcon />
          </Link>
        </Button>
      ),
    })) ?? [],
    [data]
  );

  const filteredRows = React.useMemo(() => {
    if (!search) {
      return rows;
    }

    return rows.filter((row) =>
      row[searchBy].toLowerCase().includes(search.toLowerCase())
    );
  }, [search, rows, searchBy]);

  const onRowClick = (row) => {
    if (!row) {
      return;
    }

    navigate(`/incidence/${row._id}`);
  };

  const handleSearchBy = (event) => {
    const {
      target: { value },
    } = event;

    setSearchBy(value);
  };

  return (
    <GridContainer>
      <GridItem columnSize={9}>
        <TextField
          fullWidth
          placeholder="Buscar incidencia"
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem columnSize={3}>
        <DropDown
          fullWidth
          name="searchBy"
          label="Buscar por"
          collection={SEARCH_BY}
          value={searchBy}
          onChange={handleSearchBy}
        />
      </GridItem>
      <GridItem fullWidth>
        <>
          <DataGrid
            onRowClick={onRowClick}
            headers={[
              { id: "description", label: "Description" },
              { id: "dateTime", label: "Fecha" },
              { id: "assignBy", label: "Usuario" },
              { id: "poste", label: "Poste" },
              { id: "updatedAt", label: "Actualización" },
            ]}
            loading={isLoading}
            rows={filteredRows}
            emptyStateMsg="No hay incidencias"
          />
        </>
      </GridItem>
    </GridContainer>
  );
};
