import AdminRoute from "ACL/admin";
import { Incidencia } from "Components/Incidencia";
import { RequiredAuth } from "RequiredAuth";

export const IncidencesView = () => {
  return (
    <RequiredAuth>
      <AdminRoute>
        <Incidencia />
      </AdminRoute>
    </RequiredAuth>
  );
};
