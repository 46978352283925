import React from "react";
import { MenuItem, StandardTextFieldProps, TextField } from "@mui/material";

interface Props extends StandardTextFieldProps {
  collection: { label: string; value: any }[];
  helperText?: string;
}

export const DropDown: React.FC<Props> = ({
  label,
  name,
  value,
  onChange,
  collection,
  fullWidth,
  helperText,
  disabled,
}) => {
  return (
    <TextField
      name={name}
      fullWidth={fullWidth}
      select
      label={label}
      value={value}
      helperText={helperText}
      onChange={onChange}
      disabled={disabled}
    >
      {collection.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
