import { useAuth } from "@hooks/Auth";
import { SOCKET_URL } from "constanst";
import React from "react";
import { io } from "socket.io-client";

interface NotificationContextType {
  count: number;
  notifications: NotificationType[];
  handleOnSave: (status: string) => void;
  setRead: (id: string) => void;
}

interface Poste {
  _id: string;
  name: string;
}
interface NotificationType {
  assignBy: string | null;
  callRecording: string | null;
  createdAt: string;
  dateTime: string;
  description: string;
  poste: Poste;
  type: string;
  updatedAt: string;
  read: boolean;
  _id: string;
}

const NotificationContext = React.createContext<NotificationContextType>(null);

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const socket = React.useMemo(() => {
    if (!user) {
      return null;
    }

    return io(SOCKET_URL);
  }, [user]);

  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    []
  );
  const [count, setCount] = React.useState(0);

  socket?.emit("join", "monitoring");

  socket?.on("notifications", (notification: NotificationType) => {
    setNotifications([{ ...notification, read: false }, ...notifications]);
  });

  socket?.on("updateIncidence", (data: NotificationType) => {
    const newNotifications = notifications.map((notification) => {
      if (notification._id === data._id) {
        return data;
      }

      return notification;
    });

    setNotifications(newNotifications);
  });

  socket?.on("disconnect", (reason: string) => {
    if (reason === "io server disconnect") {
      socket?.connect();
    }
  });

  const handleOnSave = (status: string) => {
    socket?.emit("updateIncidenceStatus", status);
  };

  React.useEffect(() => {
    return () => {
      socket?.off("connect");
      socket?.off("disconnect");
      socket?.off("notifications");
    };
  }, [socket]);

  const setRead = (id: string) => {
    const updatedNotifications = notifications.map((not) =>
      not._id === id ? { ...not, read: true } : not
    );

    setNotifications(updatedNotifications);
  };

  React.useEffect(() => {
    setCount(notifications.filter((not) => !not.read).length);
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{ count, notifications, handleOnSave, setRead }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return React.useContext(NotificationContext);
};
