import {
  StandardTextFieldProps,
  styled,
  TextField as UnstyledTextField,
} from "@mui/material";
import React from "react";

interface TextFieldProps extends StandardTextFieldProps {
  errorMessage?: string;
  validate?: RegExp;
}

const StyleTextField = styled(UnstyledTextField)``;

const ErrorLabel = styled("label")`
  color: ${({ theme }) => theme.palette.error.main};
  display: block;
  font-size: ${({ theme }) => theme.spacing(4)};
  padding: 0 0 ${({ theme }) => theme.spacing(2)} 0;
`;

export const TextField: React.FC<TextFieldProps> = ({
  errorMessage,
  onChange,
  validate,
  onBlur,
  ...props
}) => {
  const [isValid, setIsValid] = React.useState(true);

  const handleOnChange = (event) => {
    if (!validate) {
      return onChange?.(event);
    }

    const {
      target: { value },
    } = event;

    if (value === "") {
      setIsValid(false);
      return onChange?.(event);
    }

    setIsValid(validate.test(value));
    return onChange?.(event);
  };

  const handleOnBlur = (event) => {
    if (validate) {
      const {
        target: { value },
      } = event;

      setIsValid(validate.test(value));
    }

    onBlur?.(event);
  };

  return (
    <StyleTextField
      {...props}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      error={!isValid}
      helperText={!isValid && <ErrorLabel>{errorMessage}</ErrorLabel>}
    />
  );
};
