import styled from "@emotion/styled";

export const SmallModal = styled.div`
  position: absolute;
  top: calc(100vh - 120px);
  right: 1.5rem;
  background-color: white;
  padding: 0.5rem;
  width: 18.375rem;
  height: 6.0625rem;
  box-shadow: 1px 0.5px black;
  border-radius: 1rem;
  border: 1px solid black;

  ::before {
    content: "";
    position: absolute;
    bottom: 1.125rem;
    right: -1.13rem;
    transform: translateX(-50%) rotate(45deg);
    background-color: white;
    border-top: 1px solid black;
    border-right: 1px solid black;
    width: 18px;
    height: 18px;
  }
`;
