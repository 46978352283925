import React from "react";
import { CircularProgress, Paper as UnstyledPaper } from "@mui/material";
import { styled } from "@mui/system";

import { Modal } from "./../../Components/Modal";

interface LoaderContextProps {
  openLoader: () => void;
  closeLoader: () => void;
}
const LoaderContext = React.createContext<LoaderContextProps>(null);

interface Props {
  children: React.ReactElement;
}

export const LoaderProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  
  const openLoader = () => {
    setIsLoading(true);
  };

  const closeLoader = () => {
    setIsLoading(false);
  };

  return (
    <LoaderContext.Provider value={{ openLoader, closeLoader }}>
      <Modal noTitle isOpen={isLoading} customPaper={Paper}>
        <CircularProgress />
      </Modal>
      {children}
    </LoaderContext.Provider>
  );
};

const Paper = styled(UnstyledPaper)`
  background-color: transparent;
  border: none;
  box-shadow: none;
`;

export const useLoader = () => {
  return React.useContext(LoaderContext);
};
