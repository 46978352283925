import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "./Layout";
import {
  ProfileView,
  DashboardView,
  MonitoreoView,
  LogOutView,
  UsersView,
  PostesView,
  IncidenceByIdView,
  IncidencesView,
  ResetPasswordView,
  LoginView,
} from "views";

import "./App.css";
import { NotFoundView } from "views/notFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<DashboardView />} />
          <Route path="/login" element={<LoginView />} />
          <Route path="/logout" element={<LogOutView />} />
          <Route path="/profile" element={<ProfileView />} />
          <Route path="/monitoreo" element={<MonitoreoView />} />
          <Route path="/usuarios" element={<UsersView />} />
          <Route path="/postes" element={<PostesView />} />
          <Route path="/incidencia" element={<IncidencesView />} />
          <Route path="/incidence/:id" element={<IncidenceByIdView />} />
          <Route
            path="/reset-password/:tokenId"
            element={<ResetPasswordView />}
          />
          <Route path="*" element={<NotFoundView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
