import {
  styled,
  Paper as UnstyledPaper,
  TableCell as UnstyledTableCell,
  TableHead as UnstyledTableHead,
  TableRow as UnstyledTableRow,
} from "@mui/material";

export const Paper = styled(UnstyledPaper)`
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const Header = styled(UnstyledTableHead)`
  & th {
    border: none;
    text-align: center;
    font-weight: 900;
    ${({ theme }) => `
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.common.white};
      fontsize: ${theme.spacing(4)};
      height: ${theme.spacing(7)};
      padding: 0 ${theme.spacing(4)};
    `}

    &:first-of-type {
      border-radius: 0 0 0 1rem;
    }

    &:last-of-type {
      border-radius: 0 0 1rem 0;
    }
  }
`;

export const TableCell = styled(UnstyledTableCell)`
  border-color: ${({ theme }) => theme.palette.grey[100]};
  font-size: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(7)};
  padding: 0;
  text-align: center;
`;

export const TableRow = styled(UnstyledTableRow)<{ clicklable?: boolean }>`
  ${({ theme, clicklable }) => `
    &:nth-of-type(even) {
      background-color: ${theme.palette.grey[100]};
      color: ${theme.palette.common.black};
    }

    &:last-child td, &:last-child th {
      border: ${theme.spacing(0)};
      height: ${theme.spacing(7)};
    }

    &:hover {
      cursor:${clicklable ? "pointer" : undefined};
      background-color: ${theme.palette.action.hover};
      color: ${theme.palette.common.black};
    }

    &:first-of-type tr {
      border-radius: ${theme.spacing(4)};
    }
  `}
`;
