import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

export const LeafletMap = ({ markers }) => {

    const latitud = +(process.env.REACT_APP_LAT ?? 19.33698081233141)
    const longitude = +(process.env.REACT_APP_LNG ?? -99.56557273864748)
    const zoom = +(process.env.REACT_APP_ZOOM ?? 13)

    return (
        <MapContainer style={{ height: 'calc(100vh - 60px)' }} center={[latitud, longitude]} zoom={zoom} >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.map((marker, idx: number) => (
                <Marker position={[marker.lat, marker.lng]} key={`marker-${idx}`}>
                    <Popup>
                        <strong>{marker.name}</strong><br />
                        {marker.lat}, {marker.lng}<br />
                        {marker.kilometer}<br />
                        Estado: cerrado <br />
                        Estado: No impactado<br />
                    </Popup>
                </Marker>
            )
            )}

        </MapContainer >
    );
}