import { NotificationType } from "./notifications";

export const notificationsType = [
    "Llamada entrante",
    "Impacto",
    "Poste abierto",
    "Batería baja",
] as NotificationType[];

export const colors = ["error", "warning", "info", "success"] as (
    | "inherit"
    | "disabled"
    | "action"
    | "error"
    | "warning"
    | "info"
    | "success"
    | "primary"
    | "secondary"
)[];

export const iconStyle = {
    height: "1rem",
    width: "1rem",
    marginRight: "0.25rem",
};
