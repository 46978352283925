import React from "react";
import {
  Bar,
  BarChart as BarChartStyled,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const BarChart = ({ data, dataKey }) => {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChartStyled barSize={100} data={data}>
          <XAxis dataKey="name" allowReorder="yes" />
          <YAxis amplitude={400} />
          <Tooltip wrapperStyle={{ width: 200, backgroundColor: "#ccc" }} />
          <Bar dataKey={dataKey} barSize={30} fill="#004933" />
        </BarChartStyled>
      </ResponsiveContainer>
    </div>
  );
};
