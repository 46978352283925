import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../../hooks/Auth";

export const Logout = () => {
  const { signout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    signout(() => {
      navigate("/");
    });
  }, [signout, navigate]);

  return <div>Logout</div>;
};
