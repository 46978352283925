import React from "react";
import { Drawer } from "@mui/material";
import { MenuItems } from "./MenuItems";

interface Props {
  isOpen: boolean;
  handleDrawerToggle: () => void;
}

export const Menu: React.FC<Props> = ({ isOpen, handleDrawerToggle }) => {
  const paperStyle = {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      backgroundColor: (theme) => theme.palette.primary.main,
      border: "none",
    },
  };

  return (
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        ...paperStyle,
      }}
    >
      <MenuItems handleDrawerToggle={handleDrawerToggle} />
    </Drawer>
  );
};
