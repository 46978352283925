import React from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { DropDown } from "./../Dropdown";
import { GridContainer, GridItem } from "./..//Grid";
import { ModalChildrenProps } from "./..//Modal";
import { useAuth } from "./../../hooks/Auth";
import useFetchQuery from "@hooks/fetchData";

interface Props extends ModalChildrenProps {
  incidence: string;
  onSave: (data: any) => void;
  handleOnClose?: () => void;
}

export const AssignneForm: React.FC<Props> = ({
  handleOnClose,
  incidence,
  onSave,
}) => {
  const { user } = useAuth();
  const [assignee, setAssignee] = React.useState("");
  const { data, isLoading } = useFetchQuery("users", "getUsers");

  const users = React.useMemo(
    () =>
      data?.users.map((user) => ({
        label: `${user.name} ${user.lastname}`,
        value: user._id,
      })) ?? [],
    [data]
  );

  const handleSelectChange = ({ target: { value } }) => {
    setAssignee(value);
  };

  const handleSubmit = () => {
    onSave({
      incidence: incidence,
      assignBy: user.id,
      assignee,
      status: "INPROCESS",
      comment: "Incidencia asignada a usuario",
    });
    handleOnClose();
  };

  return (
    <>
      <DialogContent dividers>
        <GridContainer>
          <GridItem fullWidth>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <DropDown
                fullWidth
                collection={users}
                name="assignee"
                label="Usuario"
                value={assignee}
                onChange={handleSelectChange}
              />
            )}
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </>
  );
};
