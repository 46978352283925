import { NotFound } from "Components/NotFound";
import { RequiredAuth } from "RequiredAuth";

export const NotFoundView = () => {
  return (
    <RequiredAuth>
      <NotFound />
    </RequiredAuth>
  );
};
