import { Modal, ModalChildrenProps, TriggerProps } from "Components/Modal";
import { IncidenceAssignneForm } from "Components/Assignee/incidenceAssignee";
import {
  Fab as UnstyledFab,
  Button as UnstyledButton,
  styled,
} from "@mui/material";
import { Spacing } from "Components/Shared/Spacing";

interface AssigneeModalProps extends ModalChildrenProps {
  incidence: string;
  onSave: (data: any) => void;
  handleOnClose?: () => void;
  title: string;
  triggerText: string;
  type: "INPROCESS" | "CLOSE";
}

export const AssigneeModal: React.FC<AssigneeModalProps> = ({
  incidence,
  onSave,
  title,
  triggerText,
  type,
}: any) => (
  <Modal title={title}>
    <Trigger text={triggerText} />
    <IncidenceAssignneForm incidence={incidence} type={type} onSave={onSave} />
  </Modal>
);

const Trigger: React.FC<TriggerProps & { text: string }> = ({
  openModal,
  text,
}) => (
  <Button fullWidth variant="outlined" onClick={openModal}>
    <Spacing>{text}</Spacing>
  </Button>
);

export const Button = styled(UnstyledButton)`
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #000000;
  border-radius: 8px;

  & > div {
    color: black;
    padding: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Fab = styled(UnstyledFab)<{ fullWidth?: boolean }>`
  padding: 0 0.875rem;
  height: auto;
  box-shadow: none;
  cursor: default;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
`;
