export const API_URL = process.env.REACT_APP_API_URL;
export const SIP_URL = process.env.REACT_APP_SIP_URL;
export const SIP_SOCKET = process.env.REACT_APP_SIP_SOCKET;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const PUBLIC_GCP_KEY = process.env.REACT_APP_PUBLIC_GCP_KEY;
export const WS_SIP_SOCKET = process.env.REACT_APP_WS_SIP_SOCKET
export const MEDIA_CONSTRAINTS = {
  audio: true,
  video: false,
};

export const DRAWER_WIDTH = 200;
