import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#004933",
      light: "#004933",
      contrastText: "#fff",
      "50": "#D9E3E1",
    },
    secondary: {
      main: "#669BBC",
      light: "#6F9B91",
      "50": "#FDF0D5",
    },
    success: {
      main: "#039A2D",
    },
    warning: {
      main: "#FF7A00",
    },
    error: {
      main: "#C1121F",
      "900": "#780000",
    },
    info: {
      main: "#E1E422",
    },
    grey: {
      "100": "#E8E8E8",
      "500": "#BFBEBE",
    },
    common: {
      white: "#fff",
      black: "#000",
    },
    action: {
      hover: "#BFBEBE",
      selected: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeightBold: 900,
    fontWeightLight: 200,
    fontWeightRegular: 400,
  },
  spacing: [
    "0rem", //0 - 0px
    "0.25rem", //1 - 4px
    "0.5rem", //2- 8px
    "0.75rem", //3 - 12px
    "1rem", //4 - 16px
    "1.5rem", //5 - 24px
    "2rem", //6 - 32px
    "2.5rem", //7 - 40px
    "3rem", //8 - 48px
    "3.5rem", //9 - 56px
    "4rem", //10 - 64px
    "4.5rem", //11 - 72px
    "5rem", //12 - 80px
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: "#669BBC",
          border: "2px solid #669BBC",
          borderRadius: "0.5rem",
          height: "2.5rem",
          fontWeight: "bold",
          columnGap: "0.5rem",
        },
        contained: {
          color: "#fff",
          borderRadius: "1.5rem",
          height: "2.5rem",
          fontWeight: "bold",
          columnGap: "0.5rem",
        },
      },
    },
  },
});
