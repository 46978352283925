import { GridItem } from "Components/Grid";
import { Card, CardHeader, Typography, CardContent } from "@mui/material";

interface DashboardItemProps {
    title: string;
    children: React.ReactElement;
    fullWidth?: boolean;
}

export const DashboardItem: React.FC<DashboardItemProps> = ({
    title,
    children,
    fullWidth,
    ...props
}) => {
    return (
        <GridItem fullWidth={fullWidth} {...props}>
            <Card>
                <CardHeader
                    title={
                        <Typography variant="h5" fontWeight={700}>
                            {title}
                        </Typography>
                    }
                />
                <CardContent>{children}</CardContent>
            </Card>
        </GridItem>
    );
};