import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { GridContainer, GridItem } from "Components/Grid";
import { Spacing } from "Components/Shared/Spacing";

export const ProfileContainer = styled(Spacing)`
  height: 100vh;
  background-image: url(assets/profile.png);
  background-position: right 2rem bottom 3.5rem;
  background-repeat: no-repeat;
  background-size: 568px 530px;
`;

export const ItemIcon = ({ icon, text }) => (
    <GridContainer>
        <GridItem>
            <Typography alignItems="center" display="flex" gap="0.25rem">
                {icon}
                {text}
            </Typography>
        </GridItem>
    </GridContainer>
);

export const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 700;
  font-size: 1.5rem;
  font-family: Poppins;
`;
