import React from "react";
import { List, ListSubheader } from "@mui/material";
import { USER_ROLE } from "ACL/constants";
import Title from "Components/Shared/Title";
import { MenuItem } from "./menuItem";
import { useAuth } from "@hooks/Auth";
import {
  PeopleAlt as PeopleIcon,
  Sos as SosIcon,
  Route as RouteIcon,
  Logout as LogoutIcon,
  NotificationsActive as NotificationsActiveIcon,
  Person as PersonIcon,
  Dashboard,
} from "@mui/icons-material";
interface Props {
  handleDrawerToggle: () => void;
}

export const MenuItems: React.FC<Props> = ({ handleDrawerToggle }) => {
  const { user } = useAuth();

  return (
    <List
      style={{ margin: "1rem 0 0 2rem" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "transparent", paddingBottom: "1.5rem" }}
        >
          <Title variant="h5" gutterBottom>
            Monitoreo
          </Title>
          <Title variant="body1" gutterBottom>
            Hola{`, ${user.name} ${user.lastname}`}
          </Title>
        </ListSubheader>
      }
    >
      <MenuItem
        text="Dashboard"
        path="/"
        role={USER_ROLE.ADMIN}
        handleDrawerToggle={handleDrawerToggle}
      >
        <Dashboard />
      </MenuItem>
      <MenuItem
        text="Usuarios"
        path="/usuarios"
        role={USER_ROLE.ADMIN}
        handleDrawerToggle={handleDrawerToggle}
      >
        <PeopleIcon />
      </MenuItem>
      <MenuItem
        text="Postes"
        path="/postes"
        role={USER_ROLE.ADMIN}
        handleDrawerToggle={handleDrawerToggle}
      >
        <SosIcon />
      </MenuItem>
      <MenuItem
        text="Incidencias"
        path="/incidencia"
        role={USER_ROLE.ADMIN}
        handleDrawerToggle={handleDrawerToggle}
      >
        <NotificationsActiveIcon />
      </MenuItem>
      <MenuItem
        text="Monitoreo"
        path="/monitoreo"
        handleDrawerToggle={handleDrawerToggle}
      >
        <RouteIcon />
      </MenuItem>
      <MenuItem
        text="Perfil"
        path="/profile"
        handleDrawerToggle={handleDrawerToggle}
      >
        <PersonIcon />
      </MenuItem>
      <MenuItem
        text="Salir"
        path="/logout"
        handleDrawerToggle={handleDrawerToggle}
      >
        <LogoutIcon />
      </MenuItem>
    </List>
  );
};
