import React from "react";
import {
  Box as UnstyledBox,
  Button,
  CardContent as UnstyledCardContent,
  Container,
  Paper as UnstyledPaper,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";

import { Spacer } from "../Styled/Spacer";
import { useAuth } from "./../../hooks/Auth";

import { theme } from "theme";

export const Login: React.FC = () => {
  const [form, setForm] = React.useState({ user: "", password: "" });
  const { signin } = useAuth();

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { name, value } = event.currentTarget;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitHandler = () => {
    signin(form.user, form.password);
  };

  const onKeyDown = ({ code }) => {
    if (code === "Enter") {
      onSubmitHandler();
    }
  };

  return (
    <Box>
      <Container
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
        }}
        maxWidth={"xs"}
      >
        <Title variant="h2" >MONITOREO</Title>
        <Paper elevation={3}>
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom
              display="flex"
              justifyContent="center"
              lineHeight="2rem"
              fontWeight="600"
              fontFamily="Montserrat"
              sx={{ textShadow: "0px 4px 4px rgb(0 0 0 / 25%)" }}
            >
              INICIA SESIÓN
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              display="flex"
              justifyContent="center"
            >
              Ingresa tus credenciales a continuación
            </Typography>
            <Spacer padding="2rem">
              <form style={{ gap: '2rem' }}>
                <TextField
                  name="user"
                  fullWidth
                  id="username"
                  label="Usuario"
                  placeholder="nombre@ejemplo.com"
                  variant="outlined"
                  value={form.user}
                  onChange={onChangeHandler}
                  style={{ marginBottom: '2rem' }}
                  autoComplete="username"
                />
                <TextField
                  name="password"
                  fullWidth
                  type="password"
                  id="password"
                  label="Contraseña"
                  placeholder="********"
                  variant="outlined"
                  value={form.password}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDown}
                  autoComplete="current-password"
                />
              </form>
              <Button variant="contained" fullWidth onClick={onSubmitHandler}>
                Iniciar Sesión
              </Button>

              <Link
                fontWeight={600}
                fontSize="1rem"
                display="flex"
                justifyContent="center"
                color={theme.palette.secondary.main}
                underline="none"
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </Spacer>
          </CardContent>
        </Paper>
      </Container>
    </Box>
  );
};

const Title = styled(Typography)`
  position: absolute;
  left: 3.5rem;
  top: 3.5rem;
  font-weight: 600;
  font-size: 5rem;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const Box = styled(UnstyledBox)`
  background-image: url(assets/login.png);
  background-position: left 2rem bottom 1rem;
  background-repeat: no-repeat;

  @media only screen and (min-width: 600px) {
    background-size: 1100px 792px;
  }

  @media only screen and (max-width: 600px) {
    background-position: left 1rem bottom 6rem;
    background-size: 100vw;
  }
`;

const CardContent = styled(UnstyledCardContent)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Paper = styled(UnstyledPaper)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3.5rem;
  border-radius: 1rem;
  height: calc(100vh - 17.8125rem);
  min-height: 26rem;
  width: 30.3125rem;
  background-color: #ffffff80;
  backdrop-filter: blur(8px);
  box-shadow: 0px 0px 16px 2px rgb(0 0 0 / 25%);

  @media only screen and (max-width: 600px) {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translate(-50%);
  }
`;
