import React from "react";

import { useAuth } from "@hooks/Auth";
import { USER_ROLE } from "ACL/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuIcon, MenuItemButton } from "Components/Shared/MenuItem";
import { ListItemText } from "@mui/material";

export const MenuItem: React.FC<{
  text: string;
  children: React.ReactElement;
  path: string;
  role?: USER_ROLE;
  handleDrawerToggle: () => void;
}> = ({ text, children, path, handleDrawerToggle, role }) => {
  const { user } = useAuth();
  const [selected, setSelected] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    setSelected(location.pathname === path);
  }, [location, path]);

  if (role && user.role !== role) {
    return;
  }

  return (
    <MenuItemButton
      key={text}
      selected={selected}
      onClick={() => {
        handleDrawerToggle();
        navigate(path);
      }}
    >
      <MenuIcon selected={selected}>{children}</MenuIcon>
      <ListItemText primary={text} />
    </MenuItemButton>
  );
};
