import { useAuth } from "@hooks/Auth";
import {
  AlternateEmail,
  CalendarMonth,
  Email,
  Person,
  Phone,
  Badge,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { GridContainer, GridItem } from "Components/Grid";
import { ProfileContainer, SectionTitle, ItemIcon } from "./container";

export const Profile = () => {
  const theme = useTheme();
  const { user, subscriptions, extension } = useAuth();

  return (
    <ProfileContainer>
      <GridContainer>
        <GridItem columnSize={12}>
          <Typography
            fontFamily="Poppins"
            fontWeight="700"
            fontSize="2.5rem"
            lineHeight="3.75rem"
            color={
              // @ts-ignore
              theme.palette.secondary.main
            }
          >
            PERFIL
          </Typography>
        </GridItem>
        <GridItem columnSize={8} gridRow="span 12">
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <SectionTitle>Mi Perfil</SectionTitle>
              <ItemIcon
                text={`${user?.name} ${user?.lastname}`}
                icon={<Person />}
              />
              <ItemIcon text={user?.username} icon={<AlternateEmail />} />
              <ItemIcon text={user?.email} icon={<Email />} />
              <ItemIcon text={user?.phone} icon={<Phone />} />
              <ItemIcon text={user?.dob} icon={<CalendarMonth />} />
              <ItemIcon text={user?.role} icon={<Badge />} />
            </CardContent>
          </Card>
        </GridItem>
        <GridItem columnSize={4}>
          <GridContainer noPadding columns={1}>
            <GridItem>
              <Card>
                <CardContent>
                  <SectionTitle>Notification</SectionTitle>
                  <FormGroup>
                    <FormControlLabel
                      disabled
                      control={<Switch checked={subscriptions?.calls} />}
                      label="Llamadas"
                    />
                    <FormControlLabel
                      disabled
                      control={
                        <Switch checked={subscriptions?.notifications} />
                      }
                      label="Notificaciones"
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            </GridItem>
            <GridItem>
              <Card>
                <CardContent>
                  <SectionTitle>Mi Extension</SectionTitle>
                  <GridItem>
                    <Typography>{extension?.ext}</Typography>
                  </GridItem>
                  <GridItem>
                    <Typography>********</Typography>
                  </GridItem>
                </CardContent>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </ProfileContainer>
  );
};
