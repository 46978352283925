export const STATUSES = {
  UNASSIGN: "SIN ASIGNAR",
  OPEN: "ABIERTA",
  INPROCESS: "EN PROGRESO",
  CLOSE: "CERRADA",
};

export const SEARCH_BY = [
  {
    label: "Descripción",
    value: "description",
  },
  {
    label: "Usuario",
    value: "assignBy",
  }, {
    label: "Poste",
    value: "poste",
  }

]