import React from "react";
import { GridContainer, GridItem } from "Components/Grid";
import { TextField } from "Components/Shared/TextField";
import useMutationHook from "@hooks/useMutation";
import { useSnackbar } from "@hooks/SnackBar";

interface Props {
  ModalFooter?: React.JSXElementConstructor<any>;
  done?: (queryKey?: string) => void;
  type: "user" | "poste";
}

interface Extension {
  ext: number;
  password: string;
  displayName: string;
}

const ExtensionForm: React.FC<Props> = ({ ModalFooter, done, type }) => {
  const { openSnackBar } = useSnackbar();
  const [form, setForm] = React.useState({
    ext: "",
    password: "",
  });
  const userEndpoint = `user/${localStorage.getItem("userId")}/extension`;

  const { mutate: createUserExtension, data } = useMutationHook<
    { message: string },
    Extension
  >(userEndpoint, "addExtension");

  const {
    mutate: createPoste,
    data: posteData,
    isLoading: posteLoading,
  } = useMutationHook("poste", "addPoste");

  React.useEffect(() => {
    if (data) {
      localStorage.removeItem("userId");
      openSnackBar("Se asigno la extensión correctamente.");
      done('getUsers');
    }
  }, [data, done, openSnackBar]);

  React.useEffect(() => {
    if (posteData) {
      localStorage.removeItem("poste");
      openSnackBar("Se creo el poste correctamente.");
      done('postes');
    }
  }, [posteData, done, openSnackBar]);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { name, value },
  }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <GridContainer
        justifyContent="center"
        rowGap={6}
        paddingTop={4}
        paddingBottom={6}
      >
        <GridItem columnSize={7}>
          <TextField
            name="ext"
            placeholder="Extensión"
            value={form.ext}
            onChange={handleOnChange}
            fullWidth
            validate={/^\d+$/}
            errorMessage="El campo Extensión es requerido"
          />
        </GridItem>
        <GridItem columnSize={7}>
          <TextField
            name="password"
            placeholder="Contraseña"
            label="Contraseña"
            value={form.password}
            type="password"
            onChange={handleOnChange}
            fullWidth
            validate={/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/}
            errorMessage="Debe contener 8 caracteres, mínimo una letra y un número"
          />
        </GridItem>
      </GridContainer>
      <ModalFooter
        disabled={posteLoading || Object.values(form).some((value) => !value)}
        onNext={() => {
          if (type === "user") {
            createUserExtension({
              displayName: "Usuario",
              password: form.password,
              ext: Number(form.ext),
            });
          } else if (type === "poste") {
            const poste = localStorage.getItem("newPoste");
            if (!poste) {
              return;
            }

            createPoste({
              password: form.password,
              ext: Number(form.ext),
              ...JSON.parse(poste),
            });
          }
        }}
        justifyContent="end"
        columnSize={7}
      />
    </>
  );
};

export default ExtensionForm;
