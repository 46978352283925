import React from "react";
import { GridContainer, GridItem } from "Components/Grid";
import { TextField } from "Components/Shared/TextField";

import { PosteFormInitialState } from "./constants";
import { Typography, Chip } from "@mui/material";

interface Props {
  ModalFooter?: React.JSXElementConstructor<any>;
}

const PosteForm: React.FC<Props> = ({ ModalFooter }) => {
  const [form, setForm] = React.useState(PosteFormInitialState);

  const handleOnChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ currentTarget: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleDirectionChange = (newValue: string) => {
    setForm((prev) => ({ ...prev, direction: newValue }));
  };

  const nextStep = () => {
    localStorage.setItem("newPoste", JSON.stringify(form));

    return true;
  };

  return (
    <>
      <GridContainer
        paddingTop="2.5rem"
        columnSpacing={12}
        justifyContent="center"
      >
        <GridItem columnSize={7}>
          <TextField
            name="name"
            placeholder="Nombre"
            fullWidth
            value={form.name}
            onChange={handleOnChange}
            validate={/^[a-z\d\s]+$/i}
            errorMessage="El campo Nombre es requerido."
          />
        </GridItem>
        <GridItem columnSize={7}>
          <TextField
            name="lat"
            placeholder="Latitud"
            type="number"
            fullWidth
            value={form.lat}
            onChange={handleOnChange}
            validate={/^-?(?:90(?:\.0+)?|\d{1,2}(?:\.\d+)?)$/}
            errorMessage="El campo Latitud es requerido."
          />
        </GridItem>
        <GridItem columnSize={7}>
          <TextField
            name="lng"
            placeholder="Longitud"
            type="text"
            fullWidth
            value={form.lng}
            onChange={handleOnChange}
            validate={/^-?(?:90(?:\.0+)?|\d{1,2}(?:\.\d+)?)$/}
            errorMessage="El campo Longitud es requerido."
          />
        </GridItem>
        <GridItem columnSize={7}>
          <TextField
            name="kilometer"
            placeholder="Kilómetro"
            type="text"
            fullWidth
            value={form.kilometer}
            validate={/^\d+\+\d+$/}
            onChange={handleOnChange}
            errorMessage="El campo Kilómetro es requerido. Sólo se aceptan números enteros positivos"
          />
        </GridItem>
        <GridItem columnSize={7} style={{ paddingLeft: "6rem" }}>
          <div
            style={{
              paddingBottom: "2.5rem",
              paddingLeft: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1">Dirección</Typography>
            <GridItem
              columnSize={3}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <>
                <Chip
                  label="A"
                  color="secondary"
                  variant={form.direction === "A" ? "filled" : "outlined"}
                  onClick={() => handleDirectionChange("A")}
                />
                <Chip
                  label="B"
                  color="secondary"
                  variant={form.direction === "B" ? "filled" : "outlined"}
                  onClick={() => handleDirectionChange("B")}
                />
              </>
            </GridItem>
          </div>
        </GridItem>
      </GridContainer>
      <ModalFooter
        disabled={Object.values(form).some((value) => !value)}
        onNext={nextStep}
        justifyContent="end"
        columnSize={7}
      />
    </>
  );
};

export default PosteForm;
