export const getMetrics = (res, incidence) => {
  const incidenceName = getIncidenceName(incidence);

  /** Poste */
  let newPostes = res.postes;
  let getPoste = res.postes.find(
    (poste) => poste.name === incidence.poste.name
  );

  if (!getPoste) {
    const newPoste = { name: incidence.poste.name, incidencias: 0 };
    newPostes = [...newPostes, newPoste];
    getPoste = newPoste;
  }

  /** Incidencia */
  let newIncidences = res.incidencias;
  let getIncidence = newIncidences.find(
    (incidencia) => incidencia.name === incidenceName
  );

  if (!getIncidence) {
    const newIncidence = { name: incidenceName, value: 0 };
    newIncidences = [...newIncidences, newIncidence];
  }
  /** Usuarios */
  let newUsers = res.usuarios;

  if (incidence.assignBy) {
    const userName = `${incidence.assignBy?.name} ${incidence.assignBy?.lastname}`;
    let getUser = newUsers.find((usuario) => usuario.name === userName);

    if (!getUser) {
      const newUser = { name: userName, llamada: 0, sensor: 0 };
      newUsers = [...newUsers, newUser];
      getUser = newUser;
    }

    const isCall = incidence.type === "Llamada";

    newUsers = newUsers
      .map((usuario) => {
        if (usuario.name === userName) {
          if (isCall) {
            return { ...usuario, llamada: usuario.llamada + 1 };
          }

          return { ...usuario, sensor: usuario.sensor + 1 };
        }

        return usuario;
      })
      .sort();
  }

  /** Return */
  return {
    ...res,
    postes: newPostes.map((poste) =>
      poste.name === getPoste.name
        ? { ...poste, incidencias: poste.incidencias + 1 }
        : poste
    ),
    incidencias: newIncidences.map((incidencia) =>
      incidencia.name === incidenceName
        ? { ...incidencia, value: incidencia.value + 1 }
        : incidencia
    ),
    usuarios: newUsers,
  };
};

export const getIncidenceName = (incidence) => {
  if (incidence.type === "Llamada") {
    return "Llamada";
  }

  if (incidence.description.toLowerCase().includes("abierto")) {
    return "Apertura";
  }

  if (incidence.description.toLowerCase().includes("baja")) {
    return "Batería";
  }

  if (incidence.description.toLowerCase().includes("impacto")) {
    return "Impacto";
  }
};

export interface Metrics {
  postes: string[];
  incidencias: string[];
  usuarios: string[];
}
export const METRICS_DEFAULT: Metrics = {
  postes: [],
  incidencias: [],
  usuarios: [],
};
