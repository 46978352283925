import React from "react";
import ReactDOM from "react-dom/client";
import { theme } from "./theme";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./hooks/Auth";
import { SnackBarProvider } from "./hooks/SnackBar";
import { LoaderProvider } from "./hooks/loader";
import { ThemeProvider } from "@mui/material";
import { NotificationProvider } from "@hooks/notifications";
import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <SnackBarProvider>
        <LoaderProvider>
          <AuthProvider>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </AuthProvider>
        </LoaderProvider>
      </SnackBarProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
