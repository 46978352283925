import React from "react";
import {
  TableBody,
  TableContainer,
  TablePagination,
  TableRow as Row,
  Table,
  TableSortLabel,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { Spacing } from "../Spacing";
import { TableCell, TableRow, Header, Paper } from "./style";
import { isObjectEmpty } from "utils";
import { theme } from "theme";

interface DataGridHeaders {
  id: string;
  label: string | React.ReactElement;
}

interface DataGridRows {
  [key: string]: any;
}

interface Props {
  headers: DataGridHeaders[];
  rows: DataGridRows[];
  emptyStateMsg?: string;
  loading?: boolean;
  onRowClick?: (row: any) => void;
}

export const DataGrid: React.FC<Props> = ({
  headers,
  rows,
  emptyStateMsg,
  loading,
  onRowClick,
}) => {
  const [orderBy, setOrderBy] = React.useState("");
  const [order, setOrder] = React.useState<"desc" | "asc">("asc");
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [properties, setProperties] = React.useState({
    start: page,
    end: rowsPerPage,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    setProperties({
      start: page * rowsPerPage,
      end: page * rowsPerPage + rowsPerPage,
    });
  }, [page, rowsPerPage]);

  const onPageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (tableId) => () => {
    setOrderBy(tableId);
    setOrder((prev) => (prev === "desc" ? "asc" : "desc"));
  };

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    if (orderBy === "") {
      return array;
    }

    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: string,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <Header>
          <TableRow>
            {isMobile && (
              <TableCell
                sortDirection={"asc"}
                key={`tablecell-${headers[0].id}`}
              >
                <TableSortLabel
                  active={orderBy === headers[0].id}
                  direction={orderBy === headers[0].id ? order : "asc"}
                  onClick={createSortHandler(headers[0].id)}
                >
                  {headers[0].label}
                </TableSortLabel>
              </TableCell>
            )}
            {!isMobile &&
              headers.map(({ label, id }) => (
                <TableCell sortDirection={"asc"} key={`tablecell-${id}`}>
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : "asc"}
                    onClick={createSortHandler(id)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
          </TableRow>
        </Header>

        <TableBody>
          {loading &&
            Array.from({ length: rowsPerPage }).map((_, index: number) => (
              <TableRow key={`table-row-${index}`}>
                {isMobile && (
                  <TableCell key={`table-body-col-loading-${index}-${0}`}>
                    <Skeleton variant="rectangular" sx={{ margin: "0 1rem" }} />
                  </TableCell>
                )}
                {!isMobile &&
                  headers.map((_, pos) => (
                    <TableCell key={`table-body-col-loading-${index}-${pos}`}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ margin: "0 1rem" }}
                      />
                    </TableCell>
                  ))}
              </TableRow>
            ))}

          {!loading && !rows.length && (
            <TableRow>
              <TableCell colSpan={headers.length} align="center">
                <Spacing></Spacing>
                <Spacing>
                  <img
                    src="../../../assets/no_data.svg"
                    alt="No data"
                    width={300}
                  />
                </Spacing>
                {emptyStateMsg && emptyStateMsg}
                <Spacing></Spacing>
              </TableCell>
            </TableRow>
          )}

          {!loading &&
            rows.length > 0 &&
            stableSort(
              rowsPerPage > 0
                ? rows.slice(properties.start, properties.end)
                : rows,
              getComparator(order, orderBy)
            )
              .concat(
                rows.length < properties.end
                  ? Array(properties.end - rows.length).fill({})
                  : []
              )
              .map((row, index) => (
                <TableRow
                  clicklable={onRowClick ? true : undefined}
                  onClick={() => {
                    if (!isObjectEmpty(row)) {
                      onRowClick?.(row);
                    }
                  }}
                  key={`table-body-row-${index}`}
                >
                  {isMobile && (
                    <TableCell key={`table-body-col-${0}`}>
                      {row[headers[0].id]}
                    </TableCell>
                  )}
                  {!isMobile &&
                    headers.map(({ id }, pos) => (
                      <TableCell key={`table-body-col-${pos}`}>
                        {row[id]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}

          {rows.length > 0 && (
            <Row>
              <TableCell colSpan={headers.length}>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 25]}
                  component="div"
                  count={rows.length}
                  page={page}
                  onPageChange={onPageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </Row>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
