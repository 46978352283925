import { API_URL } from 'constanst';
import React from 'react';

interface FetchResult<T> {
    loading: boolean;
    data: T | null;
    error: any;
}

export default function usePost<T>(path: string): [Function, FetchResult<T>] {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<T | null>(null);
    const [error, setError] = React.useState<any>(null);

    const promise = (body: any) => {
        setLoading(true);

        fetch(`${API_URL}/${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((response) => setData(response))
            .catch((error) => setError(error))
            .finally(() => {
                setLoading(false);
            });
    };

    return [promise, { loading, data, error }];
}
