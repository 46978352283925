import { RequiredAuth } from "RequiredAuth";
import AdminRoute from "ACL/admin";
import { Users } from "Components/Users/users";

export const UsersView = () => {
  return (
    <RequiredAuth>
      <AdminRoute>
        <Users />
      </AdminRoute>
    </RequiredAuth>
  );
};
