import React from "react";
import { StyledComponent } from "@emotion/styled";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Paper as UnstyledPaper,
  styled,
} from "@mui/material";

const Paper = styled(UnstyledPaper)`
  border-radius: ${({ theme }) => theme.spacing(4)};
  max-width: 60vw !important;
  min-width: 40vw !important;
  padding: ${({ theme }) => theme.spacing(6)};

  & h2 {
    font-weight: 600;
    padding: 0;
    text-align: center;
  }
`;

interface Props {
  children: React.ReactElement | React.ReactElement[];
  title?: string;
  onClose?: () => void;
  noTitle?: boolean;
  isOpen?: boolean;
  customPaper?: StyledComponent<any>;
  noCloseBtn?: boolean;
}

export interface TriggerProps {
  openModal?: () => void;
}

export interface ModalChildrenProps {
  handleOnClose?: () => void;
}

export const Modal: React.FC<Props> = ({
  children,
  title,
  onClose,
  noTitle,
  isOpen,
  customPaper,
  noCloseBtn,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOnClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      {Array.isArray(children) &&
        React.cloneElement(children[0], { openModal })}
      <Dialog
        open={isOpen || open}
        onClose={null}
        disableEscapeKeyDown={true}
        PaperComponent={customPaper ? customPaper : Paper}
      >
        {!noTitle && <DialogTitle>{title}</DialogTitle>}
        {!noCloseBtn && (
          <IconButton
            onClick={handleOnClose}
            sx={{ position: "absolute", top: "1.5rem", right: "1rem" }}
          >
            <Close />
          </IconButton>
        )}

        {React.cloneElement(Array.isArray(children) ? children[1] : children, {
          handleOnClose,
        })}
      </Dialog>
    </>
  );
};
