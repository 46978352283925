import React from "react";
import { Grid, GridProps, GridSpacing } from "@mui/material";

interface Props extends GridProps {
  children: JSX.Element | JSX.Element[];
  rowGap?: number;
  columnSpacing?: GridSpacing;
  paddingTop?: number | string;
  noGap?: boolean;
  noPadding?: boolean;
  columns?: number;
  justifyContent?: "center" | "start" | "space-between" | "end";
  style?: React.CSSProperties;
}

export const GridContainer: React.FC<Props> = ({
  children,
  rowGap = 3,
  columnSpacing = 2,
  paddingTop = 2,
  noGap,
  noPadding,
  columns = 2,
  overflow,
  justifyContent = "flex-start",
  style,
}) => {
  if (noGap) {
    rowGap = 0;
    columnSpacing = 0;
  }

  if (noPadding) {
    paddingTop = 0;
  }

  const columnSize = 12 / columns;

  return (
    <Grid
      container
      rowGap={rowGap}
      columnSpacing={columnSpacing}
      paddingTop={paddingTop}
      overflow={overflow}
      justifyContent={justifyContent}
      style={style}
    >
      {Array.isArray(children)
        ? children.map((child, index) =>
            React.cloneElement(child, {
              parentColumnsize: columnSize,
              key: index,
            })
          )
        : React.cloneElement(children, { columnSize })}
    </Grid>
  );
};
