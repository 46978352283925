import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "theme";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle password reset logic here
  };

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      }}
    >
      <div>
        <Paper
          sx={{
            padding: theme.spacing(3),
            textAlign: "center",
            maxWidth: 600,
            margin: "auto",
            marginTop: theme.spacing(10),
            borderRadius: 10,
            [theme.breakpoints.down("xs")]: {
              marginTop: theme.spacing(4),
              borderRadius: 0,
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1">
                Reset Your Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: theme.spacing(2),
                }}
              >
                <TextField
                  fullWidth
                  label="New Password"
                  type="password"
                  required
                  sx={{ margin: "1rem" }}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <TextField
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  required
                  value={confirmPassword}
                  sx={{ margin: "1rem" }}
                  onChange={handleConfirmPasswordChange}
                />
                <Button type="submit" variant="contained" color="secondary">
                  Reset Password
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Container>
  );
}

export default ResetPassword;
