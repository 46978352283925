export const SEARCH_BY = [
    {
        label: "Nombre",
        value: "name",
    },
    {
        label: "Apellido",
        value: "lastname",
    },
    {
        label: "Usuario",
        value: "username",
    },
    {
        label: "Correo",
        value: "email",
    },
    {
        label: "Rol",
        value: "role",
    },
]