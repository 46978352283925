export const UserFormInitialState = {
  name: "",
  lastname: "",
  email: "",
  username: "",
  password: "",
  role: "",
  phone: "",
  dob: "",
};

export const PosteFormInitialState = {
  name: "",
  lat: "",
  lng: "",
  kilometer: "",
  direction: "",
};
