import {
  styled,
  ListItemButton as UnstlyedListItemButton,
  ListItemIcon as UnstyledListItemIcon,
} from "@mui/material";

export const MenuItemButton = styled(UnstlyedListItemButton)`
  ${({ theme, selected }) => `
background-color: ${
    selected ? theme.palette.common.white : "inherit"
  } !important;
border-radius: ${theme.spacing(5)} 0 0 ${theme.spacing(5)};
color: ${selected ? theme.palette.primary.main : theme.palette.common.white};
margin-left: ${theme.spacing(4)};
margin-bottom: ${theme.spacing(5)};

& span {
  font-weight: bold;
}

&:hover {
  background-color: ${theme.palette.common.white} !important;

  & > div {
    color: ${theme.palette.primary.main};
  }
}
`}
`;

export const MenuIcon = styled(UnstyledListItemIcon)<{
  selected?: boolean;
}>`
  color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : theme.palette.common.white};
  margin-right: ${({ theme }) => theme.spacing(2)};
  min-width: auto;
`;
