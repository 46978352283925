import { RequiredAuth } from "RequiredAuth";
import { Monitoreo } from "Components/Monitoring/monitoreo";

export const MonitoreoView = () => {
  return (
    <RequiredAuth>
      <Monitoreo />
    </RequiredAuth>
  );
};
