import AdminRoute from "ACL/admin";
import { Postes } from "Components/Postes/postes";
import { RequiredAuth } from "RequiredAuth";

export const PostesView = () => {
  return (
    <RequiredAuth>
      <AdminRoute>
        <Postes />
      </AdminRoute>
    </RequiredAuth>
  );
};
