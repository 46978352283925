import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import { useAuth } from "./hooks/Auth";
import { Menu } from "./Components/Header/Menu";
import { Spacing } from "Components/Shared/Spacing";
import { MonitoringAppBar } from "Components/mobile/AppBar";

export const Layout = () => {
  const { user } = useAuth();
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  if (
    !localStorage.getItem("access_token") ||
    location.pathname === "/login" ||
    !user
  ) {
    return <Outlet />;
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MonitoringAppBar handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            margin: `3.5rem 0 0 0`,
            overflow: "scroll",
          }}
        >
          <Menu isOpen={isMenuOpen} handleDrawerToggle={handleDrawerToggle} />
          {location.pathname === "/monitoreo" ? (
            <Outlet />
          ) : (
            <Spacing>
              <Outlet />
            </Spacing>
          )}
        </Box>
      </Box>
    </>
  );
};
