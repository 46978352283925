import React from "react";
import { Button, Dialog, Typography } from "@mui/material";
import {
  Phone as PhoneIcon,
  CallEnd as CallEndIcon,
  SettingsInputAntenna as SettingsInputAntennaIcon,
  LocationOn,
  Map as MapIcon,
  Sos as SosIcon,
  CompareArrows,
} from "@mui/icons-material";
import { GridContainer, GridItem } from "../../Grid";
import { useAuth } from "@hooks/Auth";
import {
  BackgroundImage,
  ButtonContainer,
  DataText,
  PhoneContainer,
  PhonePaper,
  Transition,
} from "./styles-components";
import { CallIncidenceForm } from "Components/Incidencia/form/callIncidenceForm";
import { fetchData } from "@hooks/fetchData";
import { useQuery } from "react-query";
import useMutationHook from "@hooks/useMutation";

interface Props {
  isComing: boolean;
  displayName: string;
  call?: (ext: string) => void;
  answer: () => void;
  reject: () => void;
  hangUp: () => void;

  isRegistered: boolean;
  status?: string;
}

export const SoftPhone: React.FC<Props> = ({
  isComing,
  displayName,
  answer,
  reject,
  hangUp,
  isRegistered,
}) => {
  const [isOpen, setIsOpen] = React.useState(isComing);
  const [isAnswered, setIsAnswered] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const { user } = useAuth();
  const { data } = useQuery(
    ["getPosteInfo", displayName],
    () => fetchData("poste/byParam", { field: "ext", value: displayName }),
    {
      enabled: !!displayName,
    }
  );

  const { mutate: createIncidence, data: incidenceData } = useMutationHook<
    { response: { _id: string } },
    {}
  >("poste/{posteId}/incidence", "createIncidence", "posteId");
  const posteInfo = React.useMemo(() => data?.poste.at(0) ?? {}, [data]);

  const handleHangUpButton = () => {
    if (isAnswered) {
      setIsAnswered(false);
      hangUp();
    }

    if (openForm) {
      setOpenForm(false);
    }

    reject();
  };

  React.useEffect(() => {
    setIsOpen(isComing);
  }, [isComing]);

  const handleAnswerClick = () => {
    if (posteInfo._id) {
      createIncidence({
        pathParams: {
          posteId: posteInfo._id,
        },
        type: "Llamada",
        assignBy: user.id,
        description: `Llamada entrante de ${posteInfo.name}`,
        dateTime: new Date().toISOString(),
      });
    }
    setIsAnswered(true);
    answer();
    setOpenForm(true);
    setIsOpen(false);
  };

  return (
    <>
      {openForm && incidenceData?.response._id && (
        <CallIncidenceForm
          posteInfo={posteInfo}
          hangup={handleHangUpButton}
          openForm={openForm}
          incidenceId={incidenceData?.response._id}
        />
      )}
      <PhoneIcon color={isRegistered ? "success" : "error"} />
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsOpen(false)}
        PaperComponent={PhonePaper}
      >
        <BackgroundImage src="./assets/smart-phone.png" />
        <PhoneContainer>
          <GridContainer columns={1}>
            <GridItem>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SosIcon color="error" sx={{ fontSize: "6rem" }} />
              </div>
            </GridItem>
            <GridItem>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                color="#C1121F"
                fontWeight={700}
                fontSize={"20px"}
              >
                LLAMADA ENTRANTE
              </Typography>
            </GridItem>
            <GridItem fullWidth>
              <GridContainer columns={1}>
                <DataText>
                  <SettingsInputAntennaIcon />
                  &nbsp; {posteInfo.name}
                </DataText>
                <DataText>
                  <CompareArrows />
                  &nbsp; {posteInfo.direction}
                </DataText>
                <DataText>
                  <LocationOn />
                  &nbsp;{posteInfo.kilometer}
                </DataText>
                <DataText>
                  <MapIcon />
                  &nbsp;
                  {`${posteInfo.lat}`}
                </DataText>
                <DataText>
                  <MapIcon />
                  &nbsp;
                  {`${posteInfo.lng}`}
                </DataText>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer columns={1}>
            <ButtonContainer>
              <Button
                color="success"
                onClick={handleAnswerClick}
                variant="contained"
              >
                <PhoneIcon />
              </Button>
              <Button
                onClick={handleHangUpButton}
                variant="contained"
                color="error"
              >
                <CallEndIcon />
              </Button>
            </ButtonContainer>
          </GridContainer>
        </PhoneContainer>
      </Dialog>
    </>
  );
};
