import { Paper, styled } from "@mui/material";

export const Card = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  height: calc(100vh - 20.625rem);
  width: calc(100vw - 2rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 1rem;
  overflow: scroll;

  @media only screen and (max-width: 600px) {
    top: 5rem;
    transform: none;
    width: calc(100vw - 2rem);
    height: auto;
  }
`;
