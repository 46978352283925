import React from "react";
import { Typography } from "@mui/material";
import { GridContainer } from "Components/Grid";
import { SmallModal } from "Components/Modal/smallModal";
import { DataText } from "Components/SipClient/SoftPhone/styles-components";
import { SettingsInputAntenna as SettingsInputAntennaIcon } from "@mui/icons-material";

export const CallDetail = ({ posteInfo }) => {
    const [seconds, setSeconds] = React.useState(0);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setSeconds(seconds + 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [seconds]);

    return (
        <SmallModal>
            <GridContainer columns={2}>
                <DataText>
                    <SettingsInputAntennaIcon />
                    &nbsp; {posteInfo.name}
                </DataText>
                <DataText>
                    <Typography color="#BFBEBE" fontSize="1rem">
                        {minutes.toString().padStart(2, "0")}:
                        {remainingSeconds.toString().padStart(2, "0")}
                    </Typography>
                </DataText>
            </GridContainer>
        </SmallModal>
    );
};