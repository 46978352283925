import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
} from "@mui/material";

import { DropDown } from "./../Dropdown";
import { GridContainer, GridItem } from "./../Grid";
import { ModalChildrenProps } from "./../Modal";

// import { _GetAllUsersQuery } from "./queries";
import { useAuth } from "@hooks/Auth";
import useFetchQuery from "@hooks/fetchData";

interface Props extends ModalChildrenProps {
  incidence: string;
  onSave: (data: any) => void;
  type: "INPROCESS" | "CLOSE";
  handleOnClose?: () => void;
}

export const IncidenceAssignneForm: React.FC<Props> = ({
  handleOnClose,
  incidence,
  onSave,
  type,
}) => {
  const { user } = useAuth();
  const [assignee, setAssignee] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [isDropdownDisabled, setDropdownDisabled] = React.useState(false);
  const { data, isLoading } = useFetchQuery('users', 'getUsers')

  const users = React.useMemo(
    () =>
      data?.users.map(user => ({ label: `${user.name} ${user.lastname}`, value: user._id })) ?? [],
    [data]
  );

  const handleSelectChange = ({ target: { value } }) => {
    setAssignee(value);
  };

  const handleCommentChange = ({ target: { value } }) => {
    setComment(value);
  };

  const handleSubmit = () => {
    onSave({
      incidenceId: incidence,
      assignee,
      status: type,
      comment,
    });
    handleOnClose();
  };

  React.useEffect(() => {
    if (type === "CLOSE") {
      setAssignee(user.id);
      setDropdownDisabled(true);
    }
  }, [type, user.id]);

  return (
    <div style={{ padding: 10 }}>
      <GridContainer rowGap={4}>
        <GridItem fullWidth>
          <DropDown
            fullWidth
            disabled={isDropdownDisabled}
            collection={users}
            name="assignee"
            label="Usuario"
            value={assignee}
            onChange={handleSelectChange}
          />
        </GridItem>
        <GridItem fullWidth>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Descripción</InputLabel>
            <OutlinedInput
              multiline
              inputProps={{ minRows: 3, maxRows: 10 }}
              value={comment}
              onChange={handleCommentChange}
              inputComponent={TextareaAutosize}
              label="Descripción"
            />
          </FormControl>
        </GridItem>

        <GridItem fullWidth>
          {
            !isLoading && <Button variant="contained" fullWidth onClick={handleSubmit}>
              Guardar
            </Button>
          }
        </GridItem>
      </GridContainer>
    </div>
  );
};
