import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "@hooks/Auth";

import { USER_ROLE } from "./constants";

interface AdminRouteProps {
  children: React.ReactElement;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { user } = useAuth();

  const [element, setElement] = React.useState(<Navigate to="/" />);

  React.useEffect(() => {
    if (user && user.role === USER_ROLE.ADMIN) {
      setElement(children);
      setIsLoading(false);
    }
  }, [user, children]);

  if (isLoading) {
    return;
  }

  return element;
};

export default AdminRoute;
