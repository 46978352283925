import React from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
import { MoreHoriz as MoreHorizICon, Search as SearchIcon } from "@mui/icons-material";
import { DataGrid } from "../Shared/DataGrid";
import { AddPoste } from "./Forms/addPoste";
import { DropDown } from "Components/Dropdown";
import { GridContainer, GridItem } from "Components/Grid";
import { SEARCH_BY } from "./constants";
import useFetchQuery from "@hooks/fetchData";

export const Postes: React.FC = () => {
  const [search, setSearch] = React.useState("");
  const { data, isLoading } = useFetchQuery('poste', 'postes');

  const [searchBy, setSearchBy] = React.useState(SEARCH_BY[0].value);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearch(value);
  };

  const rows = React.useMemo(
    () => data?.postes.map((poste) => ({
      ...poste,
      updatedAt: new Date(poste.updatedAt).toLocaleDateString(),
      actions: (
        <Button color="inherit">
          <MoreHorizICon />
        </Button>
      ),
    })) ?? [],
    [data]
  );

  const filteredRows = React.useMemo(() => {
    if (!search) {
      return rows;
    }

    return rows.filter((row) =>
      row[searchBy].toLowerCase().includes(search.toLowerCase())
    );
  }, [search, rows, searchBy]);

  const handleSearchBy = (event) => {
    const {
      target: { value },
    } = event;

    setSearchBy(value);
  };

  return (
    <GridContainer>
      <GridItem columnSize={9}>
        <TextField
          fullWidth
          placeholder="Buscar poste"
          value={search}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem columnSize={3}>
        <DropDown
          fullWidth
          name="searchBy"
          label="Buscar por"
          collection={SEARCH_BY}
          value={searchBy}
          onChange={handleSearchBy}
        />
      </GridItem>
      <GridItem fullWidth>
        <>
          <DataGrid
            headers={[
              { id: "name", label: "Nombre" },
              { id: "lat", label: "Latitud" },
              { id: "lng", label: "Longitud" },
              { id: "ext", label: "Extensión" },
              { id: "kilometer", label: "Kilometro" },
              { id: "direction", label: "Dirección" },
              { id: "updatedAt", label: "Actualización" },
            ]}
            loading={isLoading}
            rows={filteredRows}
            emptyStateMsg="No hay postes"
          />
          <AddPoste />
        </>
      </GridItem>
    </GridContainer>
  );
};
