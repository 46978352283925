import { Profile } from "Components/Profile";
import { RequiredAuth } from "RequiredAuth";

export const ProfileView = () => {
  return (
    <RequiredAuth>
      <Profile />
    </RequiredAuth>
  );
};
